import React, { useEffect } from 'react'

const YaTranslate = () => {
    const systemLang = window.navigator.language.slice(0,2)
  const yatranslate = {
    lang:'ru',
    langFirstVisit:systemLang
}
  useEffect(() => {
    const script = document.createElement("script");
    script.src =
      `https://translate.yandex.net/website-widget/v1/widget.js?widgetId=ytWidget&pageLang=${yatranslate.lang}&widgetTheme=light&autoMode=false`;
    script.async = true;
    document.body.appendChild(script);

    document.addEventListener('DOMContentLoaded', function () {
      // Start
      yaTranslateInit();
  })
  
  function yaTranslateInit() {
  //Добавь в условие ниже && !localStorage.getItem('yt-widget') и тогда язык не будет по умолчанию системный
      if (yatranslate.langFirstVisit && !localStorage.getItem('yt-widget')) {
          /* Если установлен язык перевода для первого посещения и в localStorage нет yt-widget */
          /* If the translation language is installed for the first visit and in localStorage no yt-widget */
          yaTranslateSetLang(yatranslate.langFirstVisit);
      }

      function yaTranslateSetLang(lang:any) {
        // Записываем выбранный язык в localStorage объект yt-widget 
        // Writing the selected language to localStorage 
        localStorage.setItem('yt-widget', JSON.stringify({
            "lang": lang,
            "active": true
        }));
    }
    yaTranslateInit()
  }
    return () => {
    };
  }, []);
  return (
    <div id="ytWidget" style={{display:'none'}}></div>
  )
}

export default YaTranslate