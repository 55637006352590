import { useEffect } from "react";

import * as styled from "./Language.style";
import {Back} from "../../Back/Back";
import LanguageList from "./LanguageList/LanguageList";

const Language = () => {
  return (
    <><div style={{padding:'20px 0 0 20px'}}>
      <Back Title="" continuation={false}/>
    </div>
      <styled.Container>
        <styled.Title>
            <h1>Выбор языка:</h1>
        </styled.Title>
        <LanguageList />
      </styled.Container>
    </>
  );
};

export default Language;
