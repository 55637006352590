import React, { useEffect, useState } from "react";
// import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import style from "./LanguageList.module.css";
import DE from "../../../../Svg/country/DE.svg";
import FR from "../../../../Svg/country/FR.svg";
import GB from "../../../../Svg/country/GB.svg";
import IT from "../../../../Svg/country/IT.svg";
import RU from "../../../../Svg/country/ru.svg";
import UA from "../../../../Svg/country/ua.svg";
import BE from "../../../../Svg/country/by.svg";
import TR from "../../../../Svg/country/tr.svg";
import PL from "../../../../Svg/country/pl.svg";
import JA from "../../../../Svg/country/jp.svg";
// import { setSelectedLanguage } from '../../../../../store/languageSlice';

const LanguageList: React.FC = () => {
  
  const navigate = useNavigate();
  useEffect(()=> {
    function yaTranslateSetLang(lang:any) {
      // Записываем выбранный язык в localStorage объект yt-widget 
      // Writing the selected language to localStorage 
      localStorage.setItem('yt-widget', JSON.stringify({
          "lang": lang,
          "active": true
      }));
  }
    yaTranslateEventHandler('click', '[data-lang]', function (el:any) {
      yaTranslateSetLang(el.getAttribute('data-lang'));
      // Перезагружаем страницу
      // Reloading the page
      localStorage.setItem('navigateAfterReload', 'true');
      window.location.reload();
        })
  function yaTranslateEventHandler(event:any, selector:any, handler:any) {
    document.addEventListener(event, function (e) {
        let el = e.target.closest(selector);
        if (el) handler(el);
    });
    }
    if (localStorage.getItem('navigateAfterReload') === 'true') {
      localStorage.removeItem('navigateAfterReload');
      setTimeout(() => {
        navigate(-1);
      }, 100); // Adjust the delay as necessary asdas
    }
  }, [])

  return (
    <div className={style.LanguageList}>
        <div className={style.LanguageItem} translate="no" data-lang="be">
        <div className={style.img}>
          <img src={BE} alt="Belarusian" />
        </div>
        <div className={style.name}>Беларускi</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="en">
        <div className={style.img}>
          <img src={GB} alt="English" />
        </div>
        <div className={style.name}>English</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="fr">
        <div className={style.img}>
          <img src={FR} alt="France" />
        </div>
        <div className={style.name}>Français</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="de">
        <div className={style.img}>
          <img src={DE} alt="Germany" />
        </div>
        <div className={style.name}>Deutsch</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="it">
        <div className={style.img}>
          <img src={IT} alt="Italy" />
        </div>
        <div className={style.name}>Italiano</div>
      </div>
    
      <div className={style.LanguageItem} translate="no" data-lang="ja">
        <div className={style.img}>
          <img src={JA} alt="Japanese" />
        </div>
        <div className={style.name}>日本語</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="pl">
        <div className={style.img}>
          <img src={PL} alt="Polish" />
        </div>
        <div className={style.name}>Polski</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="ru">
        <div className={style.img}>
          <img src={RU} translate="no" alt="Russian" />
        </div>
        <div className={style.name}>Русский</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="tr">
        <div className={style.img}>
          <img src={TR} alt="Turkish" />
        </div>
        <div className={style.name}>Türkçe</div>
      </div>
      <div className={style.LanguageItem} translate="no" data-lang="uk">
        <div className={style.img}>
          <img src={UA} alt="Ukrainian" />
        </div>
        <div className={style.name}>Українська</div>
      </div>
    </div>
  );
};

export default LanguageList;
